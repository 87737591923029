import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastService } from '../_services/toast.service';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  model: any = {};
  constructor(private toastService: ToastService, private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  scrollTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  onSubmit(data: NgForm) {
    this.sharedService.sendSlackMessage(this.model).subscribe((r) => {
      this.toastService.showSuccess('Sent successfully');
      data.resetForm();
    });


  }
}
