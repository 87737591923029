<main class="page-wrapper position-relative">
  <header class="header navbar navbar-expand-md navbar-light navbar-sticky">
    <div class="container px-0 px-xl-3">
      <a class="navbar-brand order-md-1 me-md-5 me-0 pe-lg-2" href="/">
        <img src="../assets/img/creative-agency/1aq.svg" alt="Createx Logo" width="180">
      </a>
      <div class="d-flex align-items-center order-md-3">
        <a class="btn btn-primary rounded-pill d-sm-inline-block d-none ms-3" href="/contact">Contact us</a>
        <button class="navbar-toggler ms-1 me-n3" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse1" aria-expanded="false">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <nav class="collapse navbar-collapse order-md-2" id="navbarCollapse1">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/about">About</a>
          </li>
          <li class="nav-item d-sm-none d-block">
            <a class="nav-link" href="/contact">Contact us</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <section class="container my-5 pt-md-4">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <h3 class="h6 mb-2 text-uppercase">Contact us</h3>
        <h1 class="mb-lg-5 mb-4 pb-1">Let's talk about:</h1>

        <!-- Nav tabs -->
        <div class="mb-md-5 mb-4 pb-lg-4" data-simplebar data-simplebar-auto-hide="false">
          <ul class="nav flex-md-column flex-nowrap text-nowrap" id="contactTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link h3 me-md-0 me-sm-5 me-4 mb-0 px-0 active" href="/contact" id="new-project-tab"
                data-bs-toggle="tab" role="tab" aria-controls="new-project" aria-selected="true">
                New Project
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link h3 me-md-0 me-sm-5 me-4 mb-0 px-0" href="/contact" id="general-tab"
                data-bs-toggle="tab" role="tab" aria-controls="general" aria-selected="false">
                General Enquiries
              </a>
            </li>
          </ul>
        </div>

        <!-- Address / Phone -->
        <div class="row">
          <div class="col-md-12 col-sm">
            <h3 class="h6 mb-2 text-uppercase">Address</h3>
            <ul class="mb-md-4 mb-3 pb-md-3 list-unstyled">
              <li>1 Alpha Quantum</li>
              <li>5850 San Felipe St., </li>
              <li>Suite 500</li>
              <li>Houston, TX 77057</li>
            </ul>
          </div>
          <div class="col-md-12 col-sm">
            <h3 class="h6 mb-2 text-uppercase">Phone</h3>
            <ul class="mb-md-4 mb-3 pb-md-3 list-unstyled nav-dark">
              <li><a class="nav-link p-0 fw-normal" href="phone:(832)800-335">+1 (832) 800-3355</a></li>
            </ul>
          </div>
        </div>
      </div>


      <!-- Tab content -->
      <div class="col-md-8 ms-auto">
        <div class="tab-content mt-4 pt-md-3">

          <!-- Tab: New project -->
          <div class="tab-pane fade show active" id="new-project" role="tabpanel" aria-labelledby="new-project-tab">
            <p class="lead">Have a project in mind that you think we'd be a great fit for? We'd love to know what you're
              thinking.</p>
            <form class="row needs-validation" name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm"
              novalidate>
              <div class="col-sm-6">
                <div class="mb-4">

                  <label for="contact-name-2" class="form-label-lg">Name*</label>
                  <input type="text" class="form-control" name="userName" [(ngModel)]="model.userName"
                    #userName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }" required />
                  <div *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
                    <div *ngIf="userName.errors.required">Name is required</div>
                  </div>
                </div>
                <div class="mb-4">
                  <label for="contact-company-2" class="form-label-lg">Company</label>
                  <input type="text" class="form-control" name="company" [(ngModel)]="model.company"
                    #company="ngModel" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-4">
                  <label for="contact-email-2" class="form-label-lg">Email*</label>
                  <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>
                <div class="mb-4">
                  <label for="contact-project-2" class="form-label-lg">Subject</label>
                  <input type="text" class="form-control" name="subject" [(ngModel)]="model.subject"
                    #subject="ngModel" />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-4 mt-2">
                  <label for="contact-details-2" class="form-label-lg">Message</label>
                  <textarea class="form-control" name="message" rows="3" [(ngModel)]="model.message" #message="ngModel"
                    required>
                </textarea>
                  <div *ngIf="f.submitted && message.invalid" class="invalid-feedback">
                    <div *ngIf="message.errors.required">Message is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-primary rounded-pill mt-md-4 mt-2">Send message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

</main><!-- / Main content: .page-wrapper -->


<!-- Footer -->
<footer class="footer pt-3 bg-dark">
  <div class="container py-lg-5 py-4">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-12 order-md-1 order-2 mb-md-0 mb-4">
        <ul class="nav nav-light flex-column">
          <li class="nav-item mb-2">
            <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
              <span class="text-light"> <strong>1 Alpha Quantum LLC</strong></span>

            </a>
          </li>
          <li class="nav-item mb-2">
            <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
              <span class="text-light">Address: </span>
              5850 San Felipe St., Suite 500, Houston, TX 77057
            </a>
          </li>
          <li class="nav-item mb-2">
            <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
              <span class="text-light">Call: </span>
              +1 (832) 800-3355
            </a>
          </li>
          <li class="nav-item mb-2">
            <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
              <span class="text-light">Email: </span>
              contact@1aq.co
            </a>
          </li>

        </ul>
      </div>
      <!-- <div class="col-lg-2 col-md-3 col-12 order-md-2 order-1 offset-lg-1 mb-md-0 mb-4">
          <h3 class="h6 mb-3 text-uppercase text-light">Quick links</h3>
          <ul class="nav nav-light flex-md-column flex-sm-row flex-column">
            <li class="nav-item mb-2">
              <a href="index.html" class="nav-link me-md-0 me-sm-4 p-0 fw-normal">Homepage</a>
            </li>
            <li class="nav-item mb-2">
              <a href="portfolio.html" class="nav-link me-md-0 me-sm-4 p-0 fw-normal">Portfolio</a>
            </li>
            <li class="nav-item mb-2">
              <a href="about.html" class="nav-link me-md-0 me-sm-4 p-0 fw-normal">About us</a>
            </li>
            <li class="nav-item mb-2">
              <a href="blog.html" class="nav-link me-md-0 me-sm-4 p-0 fw-normal">Blog</a>
            </li>
            <li class="nav-item mb-2">
              <a href="contacts.html" class="nav-link me-md-0 me-sm-4 p-0 fw-normal">Contacts</a>
            </li>
          </ul>
        </div> -->
      <!-- <div class="col-md-3 col-sm-6 order-md-3 order-sm-4 order-3 offset-lg-1 mb-md-0 mb-4">
          <h3 class="h6 mb-3 text-uppercase text-light">Follow us</h3>
          <a href="#" class="btn-social bs-solid rounded-circle bs-light me-2 mb-2 mt-md-0 mt-sm-1">
            <i class="ci-facebook"></i>
          </a>
          <a href="#" class="btn-social bs-solid rounded-circle bs-light me-2 mb-2 mt-md-0 mt-sm-1">
            <i class="ci-instagram"></i>
          </a>
          <a href="#" class="btn-social bs-solid rounded-circle bs-light me-2 mb-2 mt-md-0 mt-sm-1">
            <i class="ci-twitter"></i>
          </a>
          <a href="#" class="btn-social bs-solid rounded-circle bs-light me-2 mb-2 mt-md-0 mt-sm-1">
            <i class="ci-behance"></i>
          </a>
          <a href="#" class="btn-social bs-solid rounded-circle bs-light mb-2 mt-md-0 mt-sm-1">
            <i class="ci-dribbble"></i>
          </a>
        </div> -->
      <!-- <div class="col-md-3 col-sm-6 order-md-4 order-sm-3 order-4 mb-md-0 mb-4">
          <h3 class="h6 mb-3 text-uppercase text-light">Subscribe</h3>
          <form>
            <div class="input-group input-group-light mb-2 pb-1">
              <input class="form-control pe-5 rounded" type="text" placeholder="Email address*">
              <i class="ci-arrow-right lead text-light position-absolute top-50 end-0 translate-middle-y mt-n1 me-3"></i>
            </div>
            <small class="d-block form-text fs-xs line-height-base text-light">*Subscribe to our newsletter to receive early discount offers, updates and new products info.</small>
          </form>
        </div> -->
    </div>
    <div class="d-flex justify-content-between mt-2 pt-1">
      <div class="text-light">
        <span class="d-block fs-xs mb-1">
          <span class="fs-sm">&copy; </span>
          All rights reserved.
        </span>

      </div>
      <div class="d-flex align-items-end">
        <span (click)="scrollTop()" style="cursor:pointer" class="d-sm-inline-block d-none text-light fs-sm me-3">Back
          to top</span>
        <a class="btn-scroll-top position-static rounded-circle" data-scroll>
          <i class="btn-scroll-top-icon ci-angle-up"></i>
        </a>
      </div>
    </div>
  </div>
</footer>