<main class="page-wrapper position-relative">

    <header class="header navbar navbar-expand-md navbar-light navbar-sticky">
        <div class="container px-0 px-xl-3">
            <a class="navbar-brand order-md-1 me-md-5 me-0 pe-lg-2" href="/">
                <img class="navbar-brand-static" src="../assets/img/creative-agency/1aq.svg" alt="1AQ" width="180">
                <img class="navbar-brand-floating" src="../assets/img/creative-agency/1aq.svg" alt="1AQ" width="180">
            </a>
            <div class="d-flex align-items-center order-md-3">
                <a class="btn btn-primary rounded-pill d-sm-inline-block d-none ms-3" href="/contact">Contact us</a>
                <button class="navbar-toggler ms-1 me-n3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse1" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <nav class="collapse navbar-collapse order-md-2" id="navbarCollapse1">
                <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/services">Services</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/about">About</a>
                    </li>
                    <li class="nav-item d-sm-none d-block">
                        <a class="nav-link" href="/contact">Contact us</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>

    <section class="container mt-grid-gutter mb-lg-6 mb-5 py-2 pb-lg-0 pt-sm-grid-gutter">

        <div class="row">
            <div class="col-lg-6">
                <img class="mb-sm-5 mb-grid-gutter rounded" src="../assets/img/about.webp" alt="Blog image">
            </div>
            <div class="col-lg-6">
                <p class="title">1 Alpha Quantum</p>
                <p>
                    One Alpha Quantum is built around a multidisciplinary team of Programming and Developers experts, Public and Private Contract Specialists, Engineers, Supply Specialist, a sound Legal Counsel and Veterans; all who are passionate about delivering high-quality solutions to help federal and state agencies, as well as the private sector achieve their objectives. Our people bring a wealth of expertise and knowledge to every project we undertake.
                </p>
                <p>
                    As a veteran-owned and operated company, we are committed to serving our country and our community, and we believe that our commitment to excellence and service is reflected in everything we do.
                </p>
                <p>Our team leverages the latest technologies and best practices to deliver cost-effective solutions that meet the unique needs of our clients. We offer a range of services, including technological adoption planning and strategy, cloud computing, cybersecurity, application development, data analytics, business process optimization, technology infrastructure development and support, artificial intelligence adoption, and program management.</p>
                <p>We understand the importance of delivering projects on time and within budget, while maintaining the highest level of quality and security. We are committed to providing our clients with the best possible service, and we are constantly looking for ways to improve and innovate.</p>
                <p>One of our core commitments is to support other veteran-owned businesses and organizations that share our values and principles. It is our belief that by working together, we can make a positive impact on our communities and our nation.</p>
                <p>If you are looking for a trusted partner who can help you achieve your technological goals, we invite you to learn more about One Alpha Quantum and how we can help. </p>
                <p>Contact us today to schedule a consultation with our team.</p>
            </div>
        </div>

    </section>

</main>

<footer class="footer pt-3 bg-dark">
    <div class="container py-lg-5 py-4">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-12 order-md-1 order-2 mb-md-0 mb-4">
                <ul class="nav nav-light flex-column">
                    <li class="nav-item mb-2">
                        <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
                            <span class="text-light"> <strong>1 Alpha Quantum LLC</strong></span>

                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
                            <span class="text-light">Address: </span>
                            5850 San Felipe St., Suite 500, Houston, TX 77057
                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
                            <span class="text-light">Call: </span>
                            +1 (832) 800-3355
                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
                            <span class="text-light">Email: </span>
                            contact@1aq.co
                        </a>
                    </li>

                </ul>
            </div>

        </div>
        <div class="d-flex justify-content-between mt-2 pt-1">
            <div class="text-light">
                <span class="d-block fs-xs mb-1">
                    <span class="fs-sm">&copy; </span>
                    All rights reserved.
                </span>
            </div>
            <div class="d-flex align-items-end">
                <span (click)="scrollTop()" style="cursor:pointer" class="d-sm-inline-block d-none text-light fs-sm me-3">Back to top</span>
                <a class="btn-scroll-top position-static rounded-circle" data-scroll>
                    <i class="btn-scroll-top-icon ci-angle-up"></i>
                </a>
            </div>
        </div>
    </div>
</footer>