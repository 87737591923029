
<!-- Main content-->
<!-- Wraps everything except footer to push footer to the bottom of the page if there is little content -->
<main class="page-wrapper position-relative">


    <!-- Navbar -->
    <!-- Remove .navbar-sticky class to detach .navbar from page scroll -->
    <header class="header navbar navbar-expand-md navbar-dark navbar-sticky navbar-floating">
        <div class="container px-0 px-xl-3">
            <a class="navbar-brand order-md-1 me-md-5 me-0 pe-lg-2" href="/">
                <img class="navbar-brand-static" src="../assets/img/creative-agency/1aqw.svg" alt="1AQ" width="180">
                <img class="navbar-brand-floating" src="../assets/img/creative-agency/1aq.svg" alt="1AQ" width="180">
            </a>
            <div class="d-flex align-items-center order-md-3">
                <a class="btn btn-primary rounded-pill d-sm-inline-block d-none ms-3" href="/contact">Contact us</a>
                <button class="navbar-toggler ms-1 me-n3" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse1" aria-expanded="false">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <nav class="collapse navbar-collapse order-md-2" id="navbarCollapse1">
                <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/services">Services</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/about">About</a>
                    </li>
                    <li class="nav-item d-sm-none d-block">
                        <a class="nav-link" href="/contact">Contact us</a>
                    </li>
                </ul>
            </nav>
        </div>
    </header>


    <!-- Hero-->
    <section class="jarallax d-flex align-items-stretch min-vh-100 bg-dark overflow-hidden pt-5" data-jarallax
        data-speed="0.5">
        <div class="jarallax-img"
            style="background-image: url(../assets/img/creative-agency/home/stock-photo-professional-it-technical-support-specialists-and-software-programmer-working-on-computers-in-1968875962.jpg);">
        </div>
        <div class="container d-flex flex-column justify-content-around pt-5 pt-sm-4 pb-5">
            <div class="row mt-md-5 pt-sm-5">

                <div class="col-lg-6 col-md-5 order-md-1 order-2 text-md-start text-center">
                    <div class="mt-lg-5 mb-4">
                        <span class="fs-sm fw-bold text-light">Programming and Applying The Things</span>
                    </div>
                    <h1 class="mb-lg-5 display-3 text-light">Creating Intelligent Solutions for Today's Challenges</h1>
                    <div class="mt-lg-5">
                        <span class="fs-md fw-bold text-light">1 Alpha Quantum (IAQ), a veteran-owned and operated
                            information technology consulting company that delivers innovative solutions to help federal
                            agencies and the private sector achieve their objectives.</span>
                    </div>
                </div>


            </div>
            <div class="row d-md-flex d-none align-items-center mt-lg-n5">
                <div class="col-4">
                    <ul class="ps-4 list-unstyled border-start border-primary fw-bold text-muted text-uppercase lead">
                        <li>ENTERPRISE LEVEL PLATFORMS</li>
                        <li>LARGE SCALE WEB & MOBILE APPS</li>
                        <li>CLOUD FOR GOVERNMENTS</li>
                        <li>CONSULTING SERVICES</li>
                        <li>LONG TERM SUPPORT</li>
                    </ul>
                </div>
                <div class="col-4">
                    <a href="#mission" data-scroll
                        class="d-flex align-items-center justify-content-center fs-sm fw-bold text-light text-decoration-none">
                        <i class="ci-arrow-down h4 me-2 mb-0 fw-normal text-light"></i>
                        Scroll down
                    </a>
                </div>
                <div class="col-4">
                    <div class="text-end">
                        <span class="ms-auto fw-bold text-light">
                            
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Mission -->
    <section id="mission" class="container py-lg-6 py-5 my-3 mt-lg-1 mb-sm-5">
        <h3 class="h6 mb-3 pb-1 text-uppercase">What we do</h3>
        <div class="row">
            <div class="col-lg-7 col-md-6">
                <p class="h3 mb-md-0 mb-3 pb-md-0 pb-1 line-height-base">We’re a full-service DevOps agency focused on
                    creating elegant solutions to solve complex problems. <br/>
                    Our team of experts has a proven track record of delivering cost-effective, high-quality solutions that meet the unique needs of multiple and diverse clients.</p>
            </div>
            <!-- <div class="col-lg-5 col-md-6 overflow-hidden">
                <div class="d-flex justify-content-md-end flex-sm-nowrap flex-wrap mx-n4">
                    <div class="mb-sm-0 mb-4 px-4">
                        <span class="d-inline-block pb-1 fs-sm text-dark text-nowrap">Highlighted in</span>
                        <img class="d-block mt-md-3 mt-2" src="../assets/img/creative-agency/logo/forbes.svg"
                            alt="Brand logo">
                    </div>
                    <div class="mb-sm-0 mb-4 px-4">
                        <span class="d-inline-block pb-1 fs-sm text-dark text-nowrap">5 stars only</span>
                        <img class="d-block mt-md-3 mt-2" src="../assets/img/creative-agency/logo/clutch.svg"
                            alt="Brand logo">
                    </div>
                </div>
            </div> -->
        </div>
    </section>


    <!-- Approach -->
    <section class="pt-lg-3 pb-4">
        <div class="container">
            <h3 class="h6 mb-2 text-uppercase">Our approach</h3>
            <h2 class="h1 mb-lg-5 mb-4">That’s how we do it</h2>
        </div>

        <!-- Carousel -->
        <div class="container px-0">
            <div class="tns-carousel-wrapper tns-nav-outside mt-n4 ms-lg-n5">
                <div class="tns-carousel-inner pt-4 ps-lg-5" data-carousel-options='{
                "loop": false,
                "controls": false,
                "responsive": {
                  "0": {
                    "items": 1
                  },
                  "576": {
                    "items": 2
                  },
                  "768": {
                    "items": 3
                  },
                  "992": {
                    "items": 4
                  }
                }
              }'>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">01</span>
                            <span class="step-line"></span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Research &amp; Analysis</h3>
                            <p class="mb-0 text-muted">We gather all requirements and research the best vendors and architecture and create a plan of action.</p>
                        </div>
                    </div>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">02</span>
                            <span class="step-line"></span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Prototyping</h3>
                            <p class="mb-0 text-muted">We do prototypes of more complex part of the architecture to come up with the most optimal solution.</p>
                        </div>
                    </div>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">03</span>
                            <span class="step-line"></span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Pixel-perfect UI Design</h3>
                            <p class="mb-0 text-muted">We produce initial wireframes to analyize the UX and complete with high definition designs before the actual development.</p>
                        </div>
                    </div>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">04</span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Development (Coding)</h3>
                            <p class="mb-0 text-muted">We do all the software development, deployment and testing to the highest standards including perfomance testing and security audits.</p>
                        </div>
                    </div>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">05</span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Delivery</h3>
                            <p class="mb-0 text-muted">We deploy and deliver all the solutions into the cloud or at premise depending on the requirements.</p>
                        </div>
                    </div>

                    <!-- Horizontal step item -->
                    <div class="step pt-2 px-3">
                        <div class="step-head mb-3">
                            <span class="step-indicator">06</span>
                        </div>
                        <div class="step-body">
                            <h3 class="h5 mb-2">Support & Maintenence</h3>
                            <p class="mb-0 text-muted">We provide long terms support and maintenence.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>


    <!-- Services -->
    <section class="container py-lg-6 mb-md-3 mb-lg-0 py-5">
        <h3 class="h6 mb-2 text-uppercase">Capabilities</h3>
        <h2 class="h1 mb-lg-4 mb-2 pb-3">Our services</h2>
        <div class="row align-items-center">
            <div class="col-md-5 order-md-1 order-2">

                <!-- Accordion -->
                <div class="accordion" id="accordionServices">

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading1">
                            <button class="accordion-button border-0" type="button" data-bs-toggle="collapse"
                                data-binded-content="#identity" data-bs-target="#servicesCollapse-1"
                                aria-expanded="true" aria-controls="servicesCollapse-1">Ideation & Strategy</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse show" id="servicesCollapse-1"
                            aria-labelledby="servicesHeading-1" data-bs-parent="#accordionServices">
                            <div class="accordion-body">Business strategy solutions powered with market research,
                                digital transformation and business intelligence services</div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-2">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-2"
                                aria-expanded="false" aria-controls="servicesCollapse-2">Product Design</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-2"
                            aria-labelledby="servicesHeading-2" data-bs-parent="#accordionServices">
                            <div class="accordion-body">Product design and consulting solutions that will give your
                                users a digital journey to remember.</div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-23">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-3"
                                aria-expanded="false" aria-controls="servicesCollapse-3">Large Scale Web and
                                Mobile</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-3"
                            aria-labelledby="servicesHeading-3" data-bs-parent="#accordionServices">
                            <div class="accordion-body">We offer web and mobile application development. Build
                                cutting-edge mobile apps or create a web app with our end-to-end web services.</div>
                        </div>
                    </div>

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-23">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-4"
                                aria-expanded="false" aria-controls="servicesCollapse-4">Cloud services</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-4"
                            aria-labelledby="servicesHeading-4" data-bs-parent="#accordionServices">
                            <div class="accordion-body">We help businesses move to the cloud and take advantage of its
                                benefits, including increased scalability, flexibility, and cost savings.
                                Our bulletproof, elastic and infinitely scalable cloud solutions accelerate your digital
                                transformation.</div>
                        </div>
                    </div>

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-3">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-5"
                                aria-expanded="false" aria-controls="servicesCollapse-5">Consultancy Services</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-5"
                            aria-labelledby="servicesHeading-5" data-bs-parent="#accordionServices">
                            <div class="accordion-body">Our Services are designed to meet the individual needs of our
                                clients who seek to modernize, transform, and digitize their processes.
                                Our team of experts analyze our customers' needs and leverage our expertise in IoT,
                                Cloud Computing, AI, Cybersecurity, Database Management, and Fullstack Web Development
                                to offer end-to-end support or custom solutions.
                                We are committed to providing the highest quality service and support to our clients,
                                ensuring that their business processes, workflows and operational goals are achieved.
                            </div>
                        </div>
                    </div>

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-4">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-6"
                                aria-expanded="false" aria-controls="servicesCollapse-6">Long Term Support</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-6"
                            aria-labelledby="servicesHeading-6" data-bs-parent="#accordionServices">
                            <div class="accordion-body">We provide comprehensive technical support from application
                                development to training for our clients' personnel to ensure efficient and effective use
                                of our products.</div>
                        </div>
                    </div>

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-5">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#web" data-bs-target="#servicesCollapse-7" aria-expanded="false"
                                aria-controls="servicesCollapse-7">Internet of Things</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-7"
                            aria-labelledby="servicesHeading-7" data-bs-parent="#accordionServices">
                            <div class="accordion-body">We enable enterprise level digital integration and
                                transformation through intelligent assets.
                                Our people help businesses to connect and integrate the data of their devices to enable
                                seamless data analysis, and automation, leading to a cost effective oversight and
                                management of resources.</div>
                        </div>
                    </div>

                    <!-- Item -->
                    <div class="accordion-item">
                        <h3 class="accordion-header" id="servicesHeading-6">
                            <button class="accordion-button border-0 collapsed" type="button" data-bs-toggle="collapse"
                                data-binded-content="#photography" data-bs-target="#servicesCollapse-8"
                                aria-expanded="false" aria-controls="servicesCollapse-8">Emerging Tech</button>
                        </h3>
                        <div class="accordion-collapse border-0 collapse" id="servicesCollapse-8"
                            aria-labelledby="servicesHeading-8" data-bs-parent="#accordionServices">
                            <div class="accordion-body">We keep our eye on the horizon. Critical technologies evolve at
                                rapid speed, turning yesterday’s magic into today’s reality.</div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-6 col-md-7 order-md-2 order-1 ms-auto">

                <!-- Images binded to accordion -->
                <div class="binded-content mb-4">
                    <div id="identity" class="binded-item active">
                        <img class="d-block" src="../assets/img/creative-agency/home/services/01.svg"
                            alt="Identity Design &amp; Illustration">
                    </div>
                    <div id="web" class="binded-item">
                        <img class="d-block" src="../assets/img/creative-agency/home/services/02.svg"
                            alt="UX / UI Design">
                    </div>
                    <div id="photography" class="binded-item">
                        <img class="d-block" src="../assets/img/creative-agency/home/services/03.svg"
                            alt="Photography &amp; Video Production">
                    </div>
                </div>
            </div>
        </div>
    </section>



</main><!-- / Main content: .page-wrapper -->


<!-- Footer -->
<footer class="footer pt-3 bg-dark">
    <div class="container py-lg-5 py-4">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-12 order-md-1 order-2 mb-md-0 mb-4">
                <ul class="nav nav-light flex-column">
                    <li class="nav-item mb-2">
                        <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
                          <span class="text-light"> <strong>1 Alpha Quantum LLC</strong></span>
                           
                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
                            <span class="text-light">Address: </span>
                            5850 San Felipe St., Suite 500, Houston, TX 77057
                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="tel:(405)555-0128" class="nav-link p-0 fw-normal">
                            <span class="text-light">Call: </span>
                            +1 (832) 800-3355
                        </a>
                    </li>
                    <li class="nav-item mb-2">
                        <a href="mailto:hello@example.com" class="nav-link p-0 fw-normal">
                            <span class="text-light">Email: </span>
                            contact@1aq.co
                        </a>
                    </li>
      
                </ul>
            </div>

        </div>
        <div class="d-flex justify-content-between mt-2 pt-1">
            <div class="text-light">
                <span class="d-block fs-xs mb-1">
                    <span class="fs-sm">&copy; </span>
                    All rights reserved.
                </span>
            </div>
            <div class="d-flex align-items-end">
                <span (click)="scrollTop()" style="cursor:pointer" class="d-sm-inline-block d-none text-light fs-sm me-3">Back to top</span>
                <a class="btn-scroll-top position-static rounded-circle" data-scroll>
                    <i class="btn-scroll-top-icon ci-angle-up"></i>
                </a>
            </div>
        </div>
    </div>
</footer>