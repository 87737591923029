import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  httpOptions = {};

  constructor(private http: HttpClient) { }

  public sendSlackMessage(model): Observable<any> {
    const url = 'https://hooks.slack.com/services/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';
    return this.http.post(url, { text: JSON.stringify(model) });

  }
}
